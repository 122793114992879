.ant-pro-sider-menu-logo {
	padding: 0;
}

.state-error .ant-select-selection {
	border-color: #CF3535 !important;
}

.ant-input
.ant-select-selection {
	height: 34px !important;
}

.ant-select-selection__placeholder {
	font-family: Sarabun;
	font-size: 16px;
	color: #dedede;
}

.ant-select-selection:hover {
	border-color: #E83836;
}

.ant-select-dropdown-menu-item {
	font-family: Sarabun;
	font-size: 16px;
}

.ant-select-dropdown-menu-item-active {
	font-family: Sarabun;
	font-size: 16px;
	color: #ffffff !important;
	background-color: #21375e !important;
}

.ant-select-selection-selected-value {
	font-family: Sarabun;
	font-size: 16px;
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active, .ant-select-open .ant-select-selection {
	border-color: #E83836;
	box-shadow: none !important;
}

.ant-calendar-picker {
  line-height: unset;
}
.ant-calendar-picker-input {
  padding: 8px 8px 4px;
  height: auto;
  font-size: 16px;
  line-height: 27px !important;
  font-family: 'Sarabun';
  background-color: #F7F7FC;
  border-color: #F7F7FC;
}
.ant-calendar-picker-input + i {
  color: #C2C9D2;
}
.ant-calendar-picker-input:hover {
	border-color: #E83836 !important;
	box-shadow: none !important;
}

.ant-calendar-picker-input:focus {
	border-color: #E83836 !important;
	box-shadow: none !important;
}

.quotationList > div > input.ant-input {
  height: 37px !important;
  font-family: Sarabun !important;
  font-size: 16px !important;
}


.ant-modal-header {
  padding: 24px;
}
.ant-modal-close-x {
  width: 71px;
  height: 71px;
  line-height: 77px;
}
.ant-modal-close-x > i{
  color: #585858;
}
.ant-modal-title {
	font-size: 22px;
  color: #585858;
  font-family: 'sarabun';
}
.ant-modal-body {
	padding: 40px 24px;
}
.ant-modal-footer {
  padding: 24px;
}

.ant-dropdown-trigger {
  cursor: pointer !important;
}
.ant-dropdown-menu {
  padding: 0 !important;
  border-radius: 6px !important;
  overflow: hidden !important;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  /* padding: 16px !important; */
  padding: 12px 8px !important;
}
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #E83836 !important;
  color: white !important;
}
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover span, .ant-dropdown-menu-submenu-title:hover span,
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover p, .ant-dropdown-menu-submenu-title:hover p {
  color: white !important;
}
.ant-dropdown-menu-item-disabled {
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled span {
  color: #9E9E9E !important;
}

.ant-switch-checked {
	background-color: #4db433 !important;
}

/* ===== custom menu ===== */
.ant-pro-top-menu.ant-layout-header {
  display: none !important;
}

/* ===== end custom menu ===== */

/* ===== custom modal ===== */
.ant-modal-footer { padding: 16px 24px; }
.ant-modal-body { padding: 32px 24px; }
/* ===== end custom modal ===== */

/* ===== custom upload ===== */
.slip-section .ant-upload-list {
  position: relative;
}
.slip-section .ant-upload-list .ant-upload-list-item {
  position: absolute;
  top: 4px;
}
/* ===== end custom upload ===== */

.ant-checkbox-inner {
  /* width: 24px;
  height: 24px; */
  width: 16px;
  height: 16px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #E83836;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #E83836;
  border-color: #E83836;
}
.ant-checkbox-checked .ant-checkbox-inner::after,
.ant-checkbox-inner::after {
  content: '\f00c' !important;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  color: white;
  transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
  border: none !important;
  left: 50%;
}

.bottom-modal {
  width: 100% !important;
  height: 100%;
  top: unset;
}
.bottom-modal .ant-modal-content {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.bottom-modal .ant-modal-footer {
  display: none;
}

.drawer-bottom .ant-drawer-content-wrapper {
  border-radius: 8px 8px 0 0;
  overflow: auto;
}

.select-with-bg .ant-select-selection__choice > div.
.select-with-bg {
  font-family: 'sarabun' !important;
  font-size: 14px !important;
}
.select-with-bg .ant-select-selection {
  height: auto !important;
  background-color: #F7F7FC;
}
.select-with-bg .ant-select-selection input {
  line-height: 40px !important;
  font-size: 14px !important;
  height: auto !important;
}
.select-with-bg .ant-select-selection-selected-value {
  font-size: 14px !important;
  line-height: 40px !important;
}
.select-with-bg .ant-select-selection__rendered {
  line-height: unset;
  height: 38px;
}
.select-with-bg .ant-select-selection__choice {
  line-height: 32px !important;
  height: 32px !important;
}
.select-with-bg.tags .ant-select-selection__rendered,
.select-with-bg.tags .ant-select-search__field {
  line-height: 28px !important;
  height: auto !important;
}

.date-picker-with-bg {
  font-family: 'sarabun' !important;
  font-size: 14px !important;
}
.date-picker-with-bg input {
  background-color: #F7F7FC;
  height: 40px;
}
.date-picker-with-bg.input {
  background-color: #F7F7FC;
  height: 40px;
}

.ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
  margin: 0 32px 0 0;
}

.ant-time-picker {
  width: 100%;
  line-height: unset;
}
.ant-time-picker .ant-time-picker-input {
  padding: 8px 8px 4px;
  height: auto;
  font-size: 16px;
  line-height: 27px;
  font-family: 'Sarabun';
  background-color: #F7F7FC;
  border-color: #F7F7FC;
}

.broadcast-upload-preview .ant-upload-list-picture-card .ant-upload-list-item,
.broadcast-upload-preview .ant-upload-list-picture-card-container {
  width: 74px;
  height: 74px;
  padding: 0;
}

.ant-radio-inner::after {
 top: 11px !important;
 left: 11px !important;
}

.ant-radio-inner {
  top: -5px !important;
}